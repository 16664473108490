<script>
	import SavingIcon from "$lib/icons/SavingIcon.svelte";
</script>

<div>
    <SavingIcon />
</div>

<style>
    div {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: rgba(255,255,255,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>